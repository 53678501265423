@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
:root {
    --primary-color: #2A506F;
    --primary-color-deactivated: #dddddd;
    --secondary-color: #A5DE37;
    --secondary-color-light: rgba(196, 196, 196, 0.15);
    --tertiary-color: rgba(255, 255, 255, 1.0);
    --tertiary-color-light: rgba(255, 255, 255, 0.60);
    --error-color: #E05853;
    --transition-time: 0.4s;
    --border: 1px solid #e5e5e5;
    --border-error: 1px solid #E05853;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    color: #2A506F;
    text-decoration: none;
}

body {
    font: 400 20px/1.4rem Roboto, sans-serif;
    line-height: 1.2;
    background: #F8F9FD;
    -webkit-font-smoothing: antialiased;
}

h3, h4, h5, h6 {
    font-weight: 100;
}

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

input, button, textarea, select {
    font: 400 16px Roboto, sans-serif;
}

button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.content-box {
    background-color: white;
    border: var(--border);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.box {
    border: var(--border);
}

.box-horizontal {
    border-top: var(--border);
    border-bottom: var(--border);
}

.content-list {
    list-style-type: none;
}

.content-list li.flex-column:last-child {
    border-bottom: var(--border);
}

.content-item {
    border-top: var(--border);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.ellipsis-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    word-wrap: break-word;
}

.ellipsis-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    word-wrap: break-word;
}

.ellipsis-15ch {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
}

.ellipsis-n {
    overflow: hidden;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    word-wrap: break-word;
}

.nowrap {
    white-space: nowrap;
    overflow: hidden;
}

.padding-bottom-big {
    padding-bottom: 50px;
}

.padding-bottom-medium {
    padding-bottom: 30px;
}

.padding-top-small {
    padding-top: 10px;
}

.padding-top-medium {
    padding-top: 20px;
}

.padding-top-big {
    padding-top: 50px
}

.padding-top-navbar {
    padding-top: 80px
}

.padding-sides-small {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-sides-very-small {
    padding-left: 5px;
    padding-right: 5px;
}

.padding-sides-medium {
    padding-left: 30px;
    padding-right: 30px;
}

.padding-left-medium {
    padding-left: 30px;
}

.padding-left-small {
    padding-left: 20px;
}

.padding-left-very-small {
    padding-left: 10px;
}

.padding-right-very-small {
    padding-right: 10px;
}

.padding-vertical-small {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-vertical-medium {
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-vertical-big {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-vertical-verysmall {
    padding-top: 10px;
    padding-bottom: 10px;
}

.margin-auto {
    margin: auto;
}

.margin-sides-verysmall {
    margin-left: 10px;
    margin-right: 10px;
}

.margin-sides-big {
    margin-left: 40px;
    margin-right: 40px;
}

.margin-vertical-small {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-vertical-verysmall {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-top-verysmall {
    margin-top: 10px;
}

.margin-bottom-huge {
    margin-bottom: 400px;
}

.margin-big {
    margin: 50px;
}

.margin-medium {
    margin: 30px;
}

.margin-small {
    margin: 20px;
}

.margin-very-small {
    margin: 10px;
}

.margin-very-very-small {
    margin: 5px;
}

.margin-top-big {
    margin-top: 50px;
}

.margin-bottom-big {
    margin-bottom: 50px;
}

.margin-vertical-big {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-top-huge {
    margin-top: 200px;
}

.margin-top-medium {
    margin-top: 25px;
}

.margin-top-small {
    margin-top: 15px;
}

.margin-bottom-small {
    margin-bottom: 15px;
}

.margin-top-very-small {
    margin-top: 5px;
}

.font-size-medium {
    font-size: 1.2em;
}

.font-size-big {
    font-size: 2em;
}

.bold {
    font-weight: bold;
}

.flex-axis-center, .flex-axis-center div a, .flex-axis-center div {
    align-items: center;
}

/* .flex-crossaxis-center {
    justify-content: safe center;
} */

.flex-crossaxis-center::before, .flex-crossaxis-center::after {
    content: '';
    flex: 1;
}

.flex-crossaxis-end {
    justify-content: flex-end;
}

.flex-axis-end {
    align-items: flex-end;
}

.flex-space-between {
    justify-content: space-between;
}

.flex-right {
    justify-content: right;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.text-box {
    white-space: pre-wrap;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.ul-padding-sides {
    list-style-type: none;
}

.contents {
    display: contents;
}

.ul-padding-sides li {
    padding-left: 30px;
}

.ul-padding-sides-not-first {
    list-style-type: none;
}

.ul-padding-sides-not-first li:not(:first-child) {
    padding-left: 15px;
}

.ul-center li {
    justify-content: center;
    align-items: center;
}

.align-right {
    justify-content: right;
}

.margin-left-small {
    margin-left: 15px;
}

.margin-left-very-small {
    margin-left: 5px;
}

.inherit-width {
    width: inherit;
}

.icon-tertiary path {
    fill: var(--tertiary-color);
}

.icon-tertiary svg circle {
    color: var(--tertiary-color);
}

.icon-error path {
    fill: var(--error-color);
}

.icon-error svg circle {
    color: var(--error-color);
}

.icon-primary path {
    fill: var(--primary-color);
}

.icon-primary-deactivated path {
    fill: var(--primary-color-deactivated);
}

.icon-secondary path {
    fill: var(--secondary-color);
}

.icon-tertiary-light path {
    fill: var(--tertiary-color-light);
}

.color-tertiary {
    color: var(--tertiary-color);
}

.color-primary {
    color: var(--primary-color);
}

.color-secondary {
    color: var(--secondary-color);
}

.color-error {
    color: var(--error-color);
}

.border-error {
    border: var(--border-error) !important;
}

.button-primary {
    background-color: var(--primary-color);
    color: var(--tertiary-color);
    padding: 18px;
    border-radius: 10px;
    transition-duration: var(--transition-time);
}

.radius {
    border-radius: 10px;
}

.radius-rounded {
    border-radius: 50%;
}

.button-primary:hover {
    color: var(--secondary-color);
}

.button-secondary {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    padding: 18px;
    border-radius: 10px;
    transition-duration: var(--transition-time);
}

.button-secondary:hover {
    background-color: white;
}

.button-negative {
    background-color: var(--error-color);
    color: var(--tertiary-color);
    padding: 18px;
    border-radius: 10px;
    transition-duration: var(--transition-time);
}

.button-negative:hover {
    color: var(--secondary-color);
}

.button-primary-disabled {
    background-color: var(--primary-color-deactivated);
    color: var(--tertiary-color);
    padding: 18px;
    border-radius: 10px;
    transition-duration: var(--transition-time);
    cursor: default;
}

.nav-button-secondary {
    background-color: var(--secondary-color-light);
    color: var(--tertiary-color);
    padding: 25px;
    transition-duration: var(--transition-time);
}

.nav-button-secondary:hover {
    color: var(--secondary-color);
}

.nav-button-secondary-active button {
    color: var(--secondary-color);
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.flex-row-nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.padding-big {
    padding: 30px
}

.padding-medium {
    padding: 20px
}

.padding-small {
    padding: 10px
}

.padding-very-small {
    padding: 5px
}

.width-smallest {
    width: 320px;
}

.background-hover:hover {
    background-color: #f4f4f4;
    transition-duration: var(--transition-time);
}

.background-hover-strong:hover {
    background-color: #eff0f1;
    transition-duration: var(--transition-time);
}

.link-underline {
    text-decoration: underline;
}

.link:hover {
    text-decoration: underline;
}

.form input, .form button, .form textarea, .form select {
    height: 50px;
    padding: 10px;
    border: var(--border);
    margin: 10px;
}

.form textarea {
    height: 100px;
}

.flex-maxcontent {
    max-width: max-content;
}

.cursor-pointer {
    cursor: pointer;
}

.wrapword {
    white-space: -moz-pre-wrap !important;
    /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;
    /* Chrome & Safari */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    white-space: pre-wrap;
    /* CSS3 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
}

.form-edit-description {
    display: flex;
}

.form-edit-description textarea {
    border: 0;
    color: var(--primary-color);
    width: 100%;
    height: 800px;
    overflow: hidden;
}

.form-edit-name, .form-edit-name button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.form-edit-name input, .form-edit-name button {
    height: 48px;
    padding: 10px;
    border: 0;
    background-color: var(--secondary-color-light);
    color: var(--tertiary-color);
}

.search-form-secondary, .search-form-secondary button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-form-secondary input, .search-form-secondary button {
    height: 50px;
    padding: 10px;
    border: 0;
    background-color: var(--secondary-color-light);
    color: var(--primary-color);
}

.search-form-primary, .search-form-primary button {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-form-primary input, .search-form-primary button {
    height: 50px;
    padding: 10px;
    border: 0;
    background-color: var(--secondary-color-light);
    color: var(--tertiary-color-light);
}

.search-form-primary input::placeholder {
    color: var(--tertiary-color-light);
}

.background-secondary-color-light {
    background-color: var(--secondary-color-light);
}

.background-primary-color {
    background-color: var(--primary-color);
}

.background-secondary-color {
    background-color: var(--secondary-color);
}

.width100 {
    width: 100%;
}

.width50 {
    width: 50%;
}

.max-width-big {
    max-width: 120px;
}

.min-width-small {
    min-width: 40px;
}

.min-width-big {
    min-width: 120px;
}

.min-width-huge {
    min-width: 200px;
}

.max-width-huge {
    max-width: 200px;
}

.max-width-very-huge {
    max-width: 400px;
}

.max-width-giant {
    max-width: 800px;
}

.max-width-images {
    width: 100%;
    max-width: 800px;
    height: auto;
}

.width-huge {
    width: 380px;
}

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: var(--tertiary-color);
    border-radius: 10px;
    max-height: 80%;
    overflow-y: auto;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 100;
}

.inactive {
    display: none;
}

.active {
    display: block;
}

.block {
    display: block;
}

.fixed-bottom-right {
    position: fixed;
    bottom: 0;
    right: 0;
}

.fixed-bottom-right-padding {
    position: fixed;
    bottom: 5%;
    right: 2%;
}

.border-dashed {
    border: 2px dashed var(--secondary-color);
}

.iframe-dash {
    width: 100%;
    min-height: 800px;
}

.text-center {
    text-align: center
}

.max-height-50 {
    max-height: 50px;
}

.max-height-70vh {
    max-height: 70vh;
}

.max-height-50vh {
    max-height: 50vh;
}

.height-chart {
    height: 500px;
}

.icon-big svg {
    font-size: 100px;
    display: flex;
    align-items: center;
}

.icon-normal svg {
    font-size: 30px;
    display: flex;
    align-items: center;
}

.icon-medium svg {
    font-size: 50px;
    display: flex;
    align-items: center;
}

.box-square-medium {
    height: 110px;
    width: 90px;
}

.box-uuid {
    height: 110px;
    width: 90px;
    padding: 5px;
    font-family: "Andale Mono", Andale Mono, monospace;
    overflow: hidden;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    word-wrap: break-word;
}

.box-uuid-history {
    height: 60px;
    width: 50px;
    padding: 5px;
    font-family: "Andale Mono", Andale Mono, monospace;
    font-size: 10px;
    overflow: hidden;
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-word;
    word-wrap: break-word;
}

.font-courier {
    font-family: "Courier New", Courier, monospace;
}

/* Notifications */

@font-face {
    font-family: 'Notification';
    src: url("./fonts/notification.eot?s3g3t9");
    src: url("./fonts/notification.eot?#iefixs3g3t9") format("embedded-opentype"), url("./fonts/notification.woff?s3g3t9") format("woff"), url("./fonts/notification.ttf?s3g3t9") format("truetype"), url("./fonts/notification.svg?s3g3t9#notification") format("svg");
    font-weight: normal;
    font-style: normal;
}

.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 80px;
}

.notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    color: #fff;
    background-color: #ccc;
    box-shadow: 0 0 12px #999;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    margin-top: 15px;
}

.notification .message {
    color: white;
}

.notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
    color: white;
}

.notification:hover, .notification:focus {
    opacity: 1;
}

.notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
}

.notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
}

.notification:before {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -14px;
    display: block;
    font-family: 'Notification';
    width: 28px;
    height: 28px;
    font-size: 28px;
    text-align: center;
    line-height: 28px;
}

.notification-info {
    background-color: #2f96b4;
}

.notification-info:before {
    content: "";
}

.notification-success {
    background-color: #51a351;
}

.notification-success:before {
    content: "";
}

.notification-warning {
    background-color: #f89406;
}

.notification-warning:before {
    content: "";
}

.notification-error {
    background-color: var(--error-color);
}

.notification-error:before {
    content: "";
}

#small-show {
    display: none;
}

@media screen and (max-width: 750px) {
    #small-hide {
        display: none;
    }
    #small-show {
        display: block;
    }
}